import React from 'react';
import { css } from '@emotion/react';

export default function Information({ className, title, subtitle, children}) {
  return (
    <div
      className={`type--45 ${className}`}
      css={css`
        display: ${(!children) ? 'none' : 'block'};
        margin-bottom: var(--margin);
      `}
    >
      <div
        className="type--20"
        css={css`
          margin-bottom: 0.5em;

          &::before {
            content: '';
            display: inline-block;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 50%;
            background-color: var(--black);
            margin-right: 1rem;
          }
        `}
      >{title}</div>
      <div
        css={css`
          color: var(--primary);
        `}
      >{subtitle}</div>
      {children}
    </div>
  )
}
