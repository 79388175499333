import React from 'react';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';

export default class Gallery extends React.Component {
  constructor(props) {
    super();

    this.images = this.shuffleArray(props.images);
  }

  random(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.images !== nextProps.images) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div
        className={this.props.classname}
        css={css`
          display: grid;
          grid-template-columns: repeat(12, minmax(0, 1fr));
          gap: var(--gutter);
          margin: calc(1.5 * var(--bigMargin)) 0;
        `}
      >
        {this.images.length > 0 && this.images
          .filter((image, i) => i < 4)
          .map((image, i) => (
            <div
              key={i}
              className="type--15"
              css={css`
                position: relative;
                grid-column-start: ${(i % 2 === 0) ? this.random(1, 3) : this.random(7, 9)};
                grid-column-end: span ${this.random(4, 6)};
                margin-top: ${(i > 0) ? `calc(var(--gutter) * ${this.random(1, 6)})` : 0};

                @media (max-width: 700px) {
                  grid-column-start: 1;
                  grid-column-end: span 12;
                  margin-top: 0;
                }
              `}
            >
              <Image
                {...image}
                width={990}
                style={{
                  width: '100%',
                }}
                loading="eager"
              />
              {image.asset.description}
            </div>
          ))
        }
      </div>
    )
  }
}
